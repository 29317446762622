<template>
  <div class="area">
    <div class="area-section">
      <!--      Upper text-->
      <br />
      <div class="welcome-tittle-area">Areas de trabajo disponibles</div>
      <br />

      <!--    Current Areas section-->

      <b-row no-gutters>
        <b-col
          cols="12"
          sm="4"
          v-for="(area, index) in areas"
          :key="index"
          class="area-element"
        >
          <b-card
            bg-variant="dark"
            text-variant="white"
            class="area-card-element"
          >
            <b-row>
              <b-col cols="8" class="area-card-tittle">
                <div>
                  {{ area.name }}
                </div>
              </b-col>
              <b-col cols="2" class="area-card-options">
                <b-dropdown
                  :disabled="loading"
                  dropleft
                  variant="link"
                  no-caret
                  menu-class="dropdown-menu-dark"
                >
                  <template #button-content>
                    <b-icon
                      icon="three-dots-vertical"
                      class="area-options-buttons"
                    ></b-icon>
                  </template>
                  <b-dropdown-item-button link item @click="editArea(area)">
                    <b-icon icon="pencil"></b-icon> Editar
                  </b-dropdown-item-button>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item-button link @click="deleteArea(index)">
                    <b-icon icon="trash"></b-icon>  Eliminar
                  </b-dropdown-item-button>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item-button link @click="invite(area)">
                    <b-icon icon="share"></b-icon>  Invitar
                  </b-dropdown-item-button>
                </b-dropdown>
              </b-col>
            </b-row>
            <br />
            <b-card-text class="text-left">
              {{ area.description }}
            </b-card-text>

            <template #footer>
              <b-button
                :disabled="loading"
                :to="{ name: 'Categories', params: { area_id: area.id } }"
                variant="primary"
                block
              >
                Ingresar
              </b-button>
            </template>
          </b-card>
        </b-col>

        <!--      Add new area Button -->

        <b-col cols="12" sm="4" class="area-element">
          <b-card
            v-if="!loading || updated"
            bg-variant="dark"
            text-variant="white"
            class="area-card-element"
          >
            <div class="area-card-tittle d-block">Agregar nueva area</div>
            <br />
            <b-button
              :disabled="loading"
              v-b-modal.modal-create
              pill
              variant="primary"
              class="area-button"
            >
              +
            </b-button>
          </b-card>
        </b-col>
      </b-row>
    </div>

    <!--      New Area section modal-->

    <b-modal
      v-model="showCreate"
      id="modal-create"
      centered
      content-class="modal-dark"
    >
      <template #modal-header>
        <h4>Agregar nueva area de trabajo</h4>
      </template>
      <b-form-input
        :disabled="loading"
        v-model="tmpArea.name"
        placeholder="Nombre nueva area"
      ></b-form-input>
      <br />
      <b-form-input
        v-model="tmpArea.description"
        placeholder="Descripcion"
      ></b-form-input>
      <template #modal-footer>
        <b-button
          :disabled="loading"
          variant="link"
          class="area-options-buttons"
          @click="showCreate = false"
        >
          Cancelar
        </b-button>
        <b-button pill :disabled="loading" variant="primary" @click="saveArea">
          <span v-if="loading">
            <b-spinner variant="dark" small></b-spinner>
          </span>
          <span v-else> Crear nueva area </span>
        </b-button>
      </template>
    </b-modal>

    <!--     Edit area section modal-->

    <b-modal
      v-model="showEdit"
      id="modal-edit"
      centered
      content-class="modal-dark"
    >
      <template #modal-header>
        <h4>Editar area de trabajo</h4>
      </template>
      <b-form-input
        :disabled="loading"
        v-model="tmpArea.name"
        placeholder="Nombre de la area"
      ></b-form-input>
      <br />
      <b-form-input
        :disabled="loading"
        v-model="tmpArea.description"
        placeholder="Descripcion"
      ></b-form-input>
      <template #modal-footer>
        <b-button
          :disabled="loading"
          variant="link"
          class="area-options-buttons"
          @click="showEdit = false"
        >
          Cancelar
        </b-button>
        <b-button pill :disabled="loading" variant="primary" @click="saveArea">
          <span v-if="loading">
            <b-spinner variant="dark" small></b-spinner>
          </span>
          <span v-else> Cambiar </span>
        </b-button>
      </template>
    </b-modal>


    <!--     Invite to area section modal-->

    <b-modal
        v-model="showInvite"
        id="modal-invite"
        centered
        content-class="modal-dark"
    >
      <template #modal-header>
        <h4>Invitar a area de trabajo</h4>
      </template>
      <b-form-input
          :disabled="loading"
          v-model="userInvite.user_email"
          placeholder="Correo de la persona"
      ></b-form-input>
      <template #modal-footer>
        <b-button
            :disabled="loading"
            variant="link"
            class="area-options-buttons"
            @click="showInvite = false"
        >
          Cancelar
        </b-button>
        <b-button pill :disabled="loading" variant="primary" @click="inviteUser">
          <span v-if="loading">
            <b-spinner variant="dark" small></b-spinner>
          </span>
          <span v-else> Invitar </span>
        </b-button>
      </template>
    </b-modal>

    <!--    Page loader-->

    <b-spinner
      v-if="loading && !updated"
      variant="primary"
      label="Text Centered"
      class="loader"
    ></b-spinner>
  </div>
</template>

<script>
export default {
  name: "Area",
  data() {
    return {
      tmpArea: {
        id: null,
        name: "",
        description: "",
      },
      userInvite: {
        user_email: "",
        area_id: null
      },
      updated: false,
      showCreate: false,
      showEdit: false,
      showInvite: false
    };
  },
  computed: {
    areas: {
      get() {
        return this.$store.getters.stateAreas;
      },
      set(value) {
        return this.$store.commit("setAreas", value);
      },
    },
    loading() {
      return this.$store.getters.isLoading;
    },
  },
  mounted() {
    this.$store.dispatch("getAvailableAreas", this.$store.getters.stateToken);
  },
  methods: {
    async saveArea() {
      this.updated = true;

      if (this.tmpArea.id != null) {
        await this.$store.dispatch("editArea", {
          token: this.$store.getters.stateToken,
          data: this.tmpArea,
        });

        this.showEdit = false;
      } else {
        await this.$store.dispatch("createNewArea", {
          token: this.$store.getters.stateToken,
          data: this.tmpArea,
        });

        this.showCreate = false;
      }

      this.tmpArea = { id: null, name: "", description: "" };
    },
    deleteArea(index) {
      this.updated = true;
      this.$bvModal
        .msgBoxConfirm("Estas seguro de que deseas eliminar el area?", {
          contentClass: "modal-dark",
          okVariant: "danger",
          okTitle: "Eliminar",
          cancelTitle: "Cancelar",
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.$store.dispatch("deleteArea", {
              token: this.$store.getters.stateToken,
              data: { area_id: this.areas[index].id },
              index: index,
            });
          }
        })
        .catch(() => {
          alert("Opps... algo ha salido mal, intentelo mas tarde");
        });
    },
    editArea(area) {
      this.tmpArea = area;
      this.updated = true;
      this.showEdit = true;
    },
    invite(area) {
      this.userInvite.area_id = area.id;
      this.showInvite = true;
    },
   async inviteUser() {
     await this.$store.dispatch("inviteUser", {
        token: this.$store.getters.stateToken,
        data: this.userInvite
      });

     this.showInvite = false;
    }
  },
};
</script>

<style scoped>
.area-section {
  max-width: 90%;
  justify-content: center;
  margin: auto;
}

.area-element {
  margin-bottom: 3%;
  padding-right: 5px;
  padding-left: 0;
  max-width: 100%;
}

.welcome-tittle-area {
  color: white;
  text-align: left;
  font-size: 14px;
}

.area-button {
  height: 60px;
  width: 60px;
  font-weight: bold;
  color: white;
  text-align: center;
}

.area-card-element {
  height: 100%;
  width: 100%;
}

.card-footer {
  border: none;
  background-color: #343a40;
}

.area-card-tittle {
  font-size: 16px;
  font-weight: bold;
  display: flex;
}

.area-card-options {
  position: absolute;
  right: 0;
  transform: translate(-50%, -15%);
}

.area-options-buttons {
  padding: 0;
  color: #7c849f;
  text-decoration: none;
  outline: none;
}
</style>
